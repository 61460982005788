import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { primaryMedia as PrimaryMediaPropType } from 'lib/CustomPropTypes';

import {
  getPIDforPlayerKey, getPlayerKeyForPID, MSNBC_TVE_PID, STREAM_KEYS,
} from 'lib/playmaker';
import { MainMedia } from '../MainMedia';

import styles from './styles.module.scss';

const imageFlavors = {
  s: AIMS_FLAVORS.FIT_760w,
  m: AIMS_FLAVORS.FIT_1000w,
  l: AIMS_FLAVORS.FIT_760w,
};

export function Aside({
  className,
  primaryMedia,
  stickyContent,
  staticContent,
  addMediaBoxShadow,
}) {
  // Boolean: For setting DataViz sticky (toggle is-sticky)
  const { ref: staticContentRef, inView: isStaticContentCrossed } = useInView({
    threshold: 1, // The whole surface area must meet the criteria
    rootMargin: '1000% 0px -80% 0px', // Infitnite past the viewport top, bottom trigger limited to top of viewport
  });
  // Boolean: For controlling DataViz fade in/out (toggle is-hidden)
  const { ref: stickyContentRef, inView: isStickyContentCrossed } = useInView({
    threshold: 1,
    rootMargin: '1000% 0px -80% 0px',
  });

  const isEmbeddedPlaymakerLiveVideo = primaryMedia?.type === 'embeddedPlaymakerLiveVideo';
  const isMainMediaVideo = isEmbeddedPlaymakerLiveVideo
    || primaryMedia?.type === 'embeddedVideo';

  const stream = primaryMedia?.playmakerLiveVideo?.playmakerId;
  const pid = getPIDforPlayerKey(stream);
  const playmakerStreamType = getPlayerKeyForPID(pid);
  const isMSNBCTVE = playmakerStreamType === STREAM_KEYS.MSNBC_TVE;
  const isMSNBCPlaymaker = isMSNBCTVE && [MSNBC_TVE_PID].includes(pid.toString());

  return (
    <aside className={classNames(className, styles.aside)}>
      <MainMedia
        primaryMedia={primaryMedia}
        className={classNames(
          styles.mediaWrapper,
          {
            [styles.mediaBoxShadow]: addMediaBoxShadow,
            [styles.isVideo]: isMainMediaVideo && !isMSNBCPlaymaker,

          },
        )}
        captionClassName={styles.caption}
        imageFlavors={imageFlavors}
        playmakerTitleClassName={styles.playmakerTitle}
        isMSNBCPlaymaker={isMSNBCPlaymaker}
      />
      {stickyContent && (
        <div
          ref={stickyContentRef}
          className={classNames(styles.stickyWrapper, styles.contentWrapper, {
            [styles.isSticky]: isStaticContentCrossed || !staticContent,
            [styles.isHidden]: isStickyContentCrossed,
            [styles.hasVideoMain]: isMainMediaVideo,
          })}
        >
          {stickyContent}
        </div>
      )}
      {staticContent && (
        <div
          ref={staticContentRef}
          className={classNames('staticWrapper', styles.contentWrapper)}
        >
          {staticContent}
        </div>
      )}
    </aside>
  );
}

Aside.propTypes = {
  className: PropTypes.string,
  primaryMedia: PrimaryMediaPropType,
  stickyContent: PropTypes.element,
  staticContent: PropTypes.element,
  addMediaBoxShadow: PropTypes.bool,
};

Aside.defaultProps = {
  className: undefined,
  primaryMedia: null,
  stickyContent: null,
  staticContent: null,
  addMediaBoxShadow: false,
};
