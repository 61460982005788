import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import ArticleBody from 'components/Article/ArticleBody';
import { getWidgetComponent } from 'components/Article/getBodyItemComponent';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { MYNEWS_ENABLED } from 'lib/brandFeatures';
import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { logError } from 'lib/datadog';

import { Kicker } from './Kicker';
import { Header } from './Header';
import { Aside } from './Aside';
import { TwoColumnLayout } from './TwoColumnLayout';

import styles from './styles.module.scss';

/**
 * Exported for testing. Always returns false
 * @returns {false}
 */
export const shouldRenderRightRailTaboola = () => false;

/**
 * Exported for testing. Always returns object with `display: false`
 * @returns {{display: false}}
 */
export const getRightRailAdConfig = () => ({ display: false });

export function splitAsideContentFromArticleBody(articleBody) {
  if (!articleBody?.length > 0) {
    return null;
  }

  let asideDataViz;

  const bodyWithoutAsideContent = articleBody.flatMap((bodyItem) => {
    if (
      bodyItem?.widget?.name === 'IFRAMELY_EXTERNAL_EMBED'
      && !asideDataViz
    ) {
      asideDataViz = bodyItem;
      return [];
    }

    return bodyItem;
  });
  return {
    asideDataViz,
    bodyWithoutAsideContent,
  };
}

function LiveBlogTwoColumn({
  article,
  block,
  path,
  hasStickyVideo,
  vertical,
  isChromeless,
  isLiveBlogLive,
  showCreatedDate,
  stylizedTag: flag,
  latestPublishedCardDate,
}) {
  const {
    asideDataViz,
    bodyWithoutAsideContent,
  } = splitAsideContentFromArticleBody(article.body);

  const articleWithoutAsideContent = {
    ...article,
    body: bodyWithoutAsideContent,
  };

  const { ref: headerRef, inView: isHeaderInView } = useInView({
    threshold: 0,
    rootMargin: '10000% 0px -99% 0px', // LiveBlog <Header /> (Hero) intersects with top of viewport
  });

  const showUserActions = (
    getFeatureConfigForBrand(MYNEWS_ENABLED, vertical)
    && !isChromeless
  );

  return (
    <article
      key={`liveBlogWrapper-${article.id}`}
      data-testid="two-col-live-blog"
      className={classNames(
        block,
        styles.liveBlog,
        'is-live-blog',
        {
          [`${block}--hasStickyVideo`]: hasStickyVideo,
          [`${block}--active`]: isLiveBlogLive,
        },
      )}
    >
      <ErrorBoundary errorLogger={logError}>
        <Kicker>
          {flag}
        </Kicker>
      </ErrorBoundary>
      <TwoColumnLayout
        headingContent={(
          <Header
            showCreatedDate={showCreatedDate}
            article={article}
            headerRef={headerRef}
            showUserActions={showUserActions}
            publishedAt={latestPublishedCardDate || article.date?.publishedAt}
          />
        )}
        asideContent={(
          <Aside
            addMediaBoxShadow={isHeaderInView}
            primaryMedia={article.primaryMedia}
            stickyContent={(
              getWidgetComponent(asideDataViz)
            )}
            staticContent={null}
          />
        )}
        mainContent={(
          <ArticleBody
            isLiveBlog
            vertical={vertical}
            article={articleWithoutAsideContent}
            index={0}
            path={path}
            isChromeless={isChromeless}
            showCreatedDate={showCreatedDate}
            shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
            shouldRenderTaboolaFeed={false}
            getRightRailAdConfig={getRightRailAdConfig}
            disableGrid
            showUserActions={false}
            showBylineTimestamp={false}
            showInlineByline={false}
            backToTopBtnsAlignDesktop="top"
            backToTopBtnsAlignMobile="top"
          />
        )}
      />
    </article>
  );
}

LiveBlogTwoColumn.propTypes = {
  article: ArticlePropType.isRequired,
  block: PropTypes.string,
  hasStickyVideo: PropTypes.bool,
  isChromeless: PropTypes.bool,
  isLiveBlogLive: PropTypes.bool,
  path: PropTypes.string,
  showCreatedDate: PropTypes.bool,
  vertical: PropTypes.string.isRequired,
  stylizedTag: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  latestPublishedCardDate: PropTypes.string,
};

LiveBlogTwoColumn.defaultProps = {
  block: '',
  hasStickyVideo: false,
  isChromeless: false,
  isLiveBlogLive: false,
  path: '',
  showCreatedDate: true,
  stylizedTag: null,
  latestPublishedCardDate: undefined,
};

export { LiveBlogTwoColumn };
