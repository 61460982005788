import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

/**
 * Decorates any video player with static overlayed elements. Right now it just
 * supports an absolute positioned flag in the left corner but can scale to other
 * future cases.
 * @param {object} props
 * @param {React.Element} props.children
 * @param {boolean} props.showVideoFlag
 */
export function VideoPlayerDecorator({ children, showVideoFlag }) {
  const { t } = useTranslation();

  return (
    <>
      {showVideoFlag && (
        <div
          className={styles.videoFlag}
          data-testid="videoFlag"
        >
          {t('LIVE')}
        </div>
      )}
      {children}
    </>
  );
}

VideoPlayerDecorator.propTypes = {
  children: PropTypes.node.isRequired,
  showVideoFlag: PropTypes.bool,
};

VideoPlayerDecorator.defaultProps = {
  showVideoFlag: false,
};
