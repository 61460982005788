import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  image as ImagePropType,
  video as VideoPropType,
  socialMediaProfiles as SocialPropType,
} from 'lib/CustomPropTypes';
import { getMainArtAutoPlayValues } from 'lib/getMainArtAutoPlayValues';
import { VerticalContext } from 'lib/ContextTypes';

import Caption from 'components/Caption';
import { Picture } from 'components/Picture';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoContainer } from 'components/VideoContainer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';
import { VideoPlayerDecorator } from 'components/VideoPlayerDecorator';
import InlineVideo from 'components/InlineVideo';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import { MSNBCPlaymaker } from 'components/MSNBCPlaymaker';

export function MainMedia({
  className,
  primaryMedia,
  imageFlavors,
  captionClassName,
  socialMediaProfiles,
  isChromeless,
  isMSNBCPlaymaker,
}) {
  const vertical = useContext(VerticalContext);

  if (!primaryMedia) return null;

  const {
    autoPlay,
    onlyAutoplayMuted,
  } = getMainArtAutoPlayValues(primaryMedia, isChromeless, vertical);

  const stream = primaryMedia?.playmakerLiveVideo?.playmakerId;
  const isEmbeddedPlaymakerLiveVideo = primaryMedia?.type === 'embeddedPlaymakerLiveVideo';

  const mainMedia = (
    isEmbeddedPlaymakerLiveVideo && isMSNBCPlaymaker && (
      <MSNBCPlaymaker />
    )
  )
  || (
    isEmbeddedPlaymakerLiveVideo && (
      <VideoPlayerDecorator showVideoFlag>
        <OmegaFeatureFlagSwitch
          ifOmega={(
            <OmegaVideoPlayer
              stream={stream}
              autoplay={autoPlay}
              mutedAutoplay={onlyAutoplayMuted}
              stickyEnabled
            />
          )}
        >
          <VideoContainer stickyEnabled>
            <TapToUnmuteButton />
            <CoreVideoPlayer
              stream={stream}
              autoplay={autoPlay}
              mutedAutoplay={onlyAutoplayMuted}
            />
            <VideoControlRack />
          </VideoContainer>
        </OmegaFeatureFlagSwitch>
      </VideoPlayerDecorator>
    )
  )
    || (
      primaryMedia?.type === 'embeddedVideo'
      && (
        <InlineVideo
          loadingLazy={false}
          autoplayCapabilities={{
            canAutoplayMuted: true,
            canAutoplayUnmuted: false,
            isTestingAutoplayCapabilities: false,
          }}
          isHero
          social={socialMediaProfiles}
          video={primaryMedia.video}
          autoPlay={autoPlay}
          onlyAutoplayMuted={onlyAutoplayMuted}
          vertical={vertical}
          pipAlignDesktop="bottom"
          pipAlignMobile="bottom"
          disableSticky={false}
        />
      )
    )
    || (
      primaryMedia?.type === 'embeddedImage'
      && (
        <figure>
          <Picture
            preload
            loadingLazy={false}
            url={primaryMedia?.image?.url?.primary}
            alt={primaryMedia?.image?.url?.primary}
            flavor="fit"
            responsiveFlavors={imageFlavors}
            sizes={imageFlavors}
            originalHeight={primaryMedia?.image?.height}
            originalWidth={primaryMedia?.image?.width}
          />
          <Caption
            className={captionClassName}
            image={primaryMedia?.image}
          />
        </figure>
      )
    );

  return (
    <div className={className} data-testid="lb-two-column-main-media">
      {mainMedia}
    </div>
  );
}

MainMedia.propTypes = {
  className: PropTypes.string,
  captionClassName: PropTypes.string,
  primaryMedia: PropTypes.oneOfType([ImagePropType, VideoPropType]),
  imageFlavors: PropTypes.objectOf(PropTypes.string),
  socialMediaProfiles: SocialPropType,
  isChromeless: PropTypes.bool,
  isMSNBCPlaymaker: PropTypes.bool,
};

MainMedia.defaultProps = {
  className: undefined,
  captionClassName: undefined,
  primaryMedia: null,
  imageFlavors: null,
  socialMediaProfiles: null,
  isChromeless: false,
  isMSNBCPlaymaker: false,
};
