import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addClassesToElement } from 'lib/addClassesToElement';
import { logError } from 'lib/datadog';

import { ErrorBoundary } from 'components/ErrorBoundary';

import styles from './styles.module.scss';

export function TwoColumnLayout({
  headingContent,
  asideContent,
  mainContent,
  className,
}) {
  return (
    <div className={classNames(styles.twoColumnLayout, className)}>
      <ErrorBoundary errorLogger={logError}>
        {addClassesToElement(headingContent, styles.gridAreaHeading)}
      </ErrorBoundary>
      <ErrorBoundary errorLogger={logError}>
        {addClassesToElement(asideContent, styles.gridAreaAside)}
      </ErrorBoundary>
      <ErrorBoundary errorLogger={logError}>
        {addClassesToElement(mainContent, styles.gridAreaMain)}
      </ErrorBoundary>
    </div>
  );
}

TwoColumnLayout.propTypes = {
  headingContent: PropTypes.element,
  asideContent: PropTypes.element,
  mainContent: PropTypes.element,
  className: PropTypes.string,
};

TwoColumnLayout.defaultProps = {
  headingContent: null,
  asideContent: null,
  mainContent: null,
  className: undefined,
};
