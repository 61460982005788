import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LiveBlogContext } from 'lib/ContextTypes';

import styles from './styles.module.scss';

export function Kicker({ children }) {
  const { isLive } = useContext(LiveBlogContext);
  return (
    <div className={classNames(styles.kicker, { 'is-live': isLive })} data-testid="lb-two-col-kicker">
      <div className={styles.kickerWrapper}>
        {children}
      </div>
    </div>
  );
}

Kicker.propTypes = {
  children: PropTypes.element,
};

Kicker.defaultProps = {
  children: null,
};
