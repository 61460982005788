import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { GeoRestriction } from 'components/TVE/GeoRestriction';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import CallToAction from 'components/TVE/CallToAction';
import Player from 'components/TVE/Player';
import ProviderSelect from 'components/TVE/ProviderSelect';
import TempPass from 'components/TVE/TempPass';
import { ERROR_TYPES } from 'components/TVE/Slate';
import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import './styles.themed.scss';
import classNames from 'classnames';
import { MSNBCPlaymakerAuthedSubheader } from './MSNBCPlaymakerAuthedSubheader';
import { block } from './helper';

const {
  publicRuntimeConfig: {
    DISABLE_AUTH_TVE_MSNBC,
  },
} = getConfig();

const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  epgCurrent: tve.epgCurrent,
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  videoError: video.error,
  provider: tve.provider,
});

function MSNBCPlaymaker(props) {
  const {
    authenticated,
    hasTempPass,
    providerSelectVisible,
    videoError,
    isHeaderInView,
  } = props;

  const iframeRef = React.useRef(null);

  return (
    <GeoRestriction>
      {!DISABLE_AUTH_TVE_MSNBC && (
        <>
          <WithTVEAuthProvider iframeRef={iframeRef} />
          {videoError !== ERROR_TYPES.BROWSER && (
            <TempPass
              checkTempPass={authenticated === false && hasTempPass === false}
            />
          )}
        </>
      )}

      <section className={block}>
        <div className={classNames(
          `${block}__inner`,
        )}
        >
          {!DISABLE_AUTH_TVE_MSNBC && (
            <>
              {providerSelectVisible && (
                <ProviderSelect iframeRef={iframeRef} />
              )}
              <CallToAction showAuthedFull={false} />
            </>
          )}

          <div className={`${block}__player-outer`}>
            <Player
              bypassAuth={DISABLE_AUTH_TVE_MSNBC}
              autoplayMuted
            />
          </div>

        </div>
        {authenticated && !hasTempPass && (
          <MSNBCPlaymakerAuthedSubheader isHeaderInView={isHeaderInView} />
        )}
      </section>
    </GeoRestriction>
  );
}

MSNBCPlaymaker.propTypes = {
  authenticated: PropTypes.bool,
  hasTempPass: PropTypes.bool,
  providerSelectVisible: PropTypes.bool,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  isHeaderInView: PropTypes.bool,
};

MSNBCPlaymaker.defaultProps = {
  authenticated: null,
  hasTempPass: null,
  providerSelectVisible: false,
  videoError: false,
  isHeaderInView: false,
};

const LiveMSNBCEmbedWrapper = connect(mapStateToProps)(
  MSNBCPlaymaker,
);


export { LiveMSNBCEmbedWrapper as MSNBCPlaymaker };


