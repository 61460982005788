import React, { memo } from 'react';
import Head from 'next/head';
import get from 'lodash.get';
import {
  article as ArticlePropType,
  liveBlogCard as LiveBlogCardPropType,
} from 'lib/CustomPropTypes';

import {
  buildBlogPosting,
  buildBreadcrumbs,
  buildDataset,
  buildLiveBlog,
  buildNewsArticle,
} from 'lib/LDJson';

import { shareImage } from 'lib/social';
import { getCanonical } from 'lib/urlUtils';
import { resolveOnTheShowMetadata, useBroadcastEvent } from 'components/PageMetadata/metadataUtils';
import { isSocialCrawlBotUserAgent } from 'lib/isSocialCrawlBotUserAgent';
import PropTypes from 'prop-types';

/**
  * Check if the card has Metadata for the given fields
  * @param {Card} source
  * @param {string} path
  * @param {string[]} oneOf
  * @returns {boolean}
  */
const hasMetadataField = (source, path, oneOf = []) => {
  const field = get(source, path, false);
  if (field && typeof field === 'object') {
    return oneOf.some((key) => typeof field[key] !== 'undefined' && !!field[key]);
  }
  return false;
};


/**
 *
 * @param {object} params
 * @param {Article} params.article
 * @param {Card[]} params.cards
 * @param {Card} params.card
 * @param {VerticalType} params.vertical
 * @param {boolean} params.isRegularBlog
 * @param {string} params.userAgent
 * @param {string} params.fullUrl
 * @param {string} [params.cardOverrideId]
 * @returns
 */
function constructMetadata(article, card, vertical, userAgent, fullUrl) {
  // Constant article data
  const {
    nativeAd, primaryImage, searchable, socialImage, teaseImage, url,
  } = article;

  // Constant card data
  const cardTeaseImage = (
    card?.teaseImage?.url?.primary
     || card?.cardData?.teaseImage
  );
  // Fields overwritable by card
  let { description, headline } = article;
  const fields = ['seo', 'social', 'primary'];

  if (card) {
    // Overwrite with card description
    if (hasMetadataField(card, 'cardData.description', fields)) {
      ({ description } = card.cardData);
    } else if (hasMetadataField(card, 'description', fields)) {
      ({ description } = card);
    }
    // Overwrite with card headline
    if (hasMetadataField(card, 'cardData.headline', fields)) {
      ({ headline } = card.cardData);
    } else if (hasMetadataField(card, 'headline', fields)) {
      ({ headline } = card);
    }
  }

  // Image
  const image = socialImage || teaseImage || primaryImage || {};
  const imageUrl = cardTeaseImage || image?.url?.primary;

  // URLs
  const primaryURL = getCanonical(url);

  const ogUrl = userAgent && fullUrl && isSocialCrawlBotUserAgent(userAgent)
    ? fullUrl
    : primaryURL;

  const onTheShowMetadata = resolveOnTheShowMetadata(article.taxonomy, vertical);

  // article headline should always be used as the `<title>` for SEO purposes
  const titleTagValue = article.headline.seo || article.headline.primary;

  return (
    <Head>
      <title key="liveBlog-title">{titleTagValue}</title>
      <meta name="description" content={description.seo || description.primary} />
      <link rel="canonical" href={primaryURL} />

      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={headline.social || headline.primary} />
      <meta property="og:description" content={description.primary || description.seo} />

      <meta property="og:image" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:secure_url" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:type" content={image.encodingFormat || 'image/gif'} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="article" />

      <meta name="twitter:image:src" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:image" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:title" content={headline.social || headline.primary} />
      <meta name="twitter:description" content={description.primary || description.seo} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />
      {(nativeAd || !searchable) && <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />}

      {onTheShowMetadata}
    </Head>
  );
}

/**
 * @param {object} props
 * @param {*} props.article
 * @param {*} props.cards
 * @param {*} props.card
 * @param {string} props.vertical
 * @param {boolean} props.isRegularBlog
 * @param {string} props.cardOverrideId
 * @param {string} props.userAgent
 * @param {string} props.fullUrl
 * @returns {React.JSX}
 */
function LiveBlogMetadata({
  article,
  cards: cardsProp,
  card,
  vertical,
  isRegularBlog,
  cardOverrideId,
  userAgent,
  fullUrl,
}) {
  const cards = cardsProp || [];

  let liveBlogLDJson;
  if (isRegularBlog) {
    liveBlogLDJson = buildBlogPosting(article, vertical);
    if (article.body && Array.isArray(article.body)) {
      const articleBody = get(article, 'body[0].html', '');
      if (articleBody.length) {
        liveBlogLDJson.articleBody = articleBody;
      }
    }
  } else {
    // Use article data
    liveBlogLDJson = buildLiveBlog(article, vertical);
  }

  const newsArticleLdJson = buildNewsArticle(article, vertical);
  // remove dateCreated from LDJson on LiveBlog
  delete newsArticleLdJson.dateCreated;

  // set dateModified as mostRecent cards date on liveBlog
  const mostRecentCardDateModified = get(liveBlogLDJson, 'liveBlogUpdate[0].dateModified');
  newsArticleLdJson.dateModified = mostRecentCardDateModified || newsArticleLdJson.dateModified;

  liveBlogLDJson.dateModified = mostRecentCardDateModified || liveBlogLDJson.dateModified;

  const cardOverride = cards.find(({ id }) => id === cardOverrideId);
  const liveVideoJsonLdMetadata = useBroadcastEvent(article);

  const breadcrumbsLdJson = vertical === 'select' ? buildBreadcrumbs(article) : null;

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(buildDataset(article, vertical)),
          }}
        />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(newsArticleLdJson),
          }}
        />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(liveBlogLDJson),
          }}
        />
        {liveVideoJsonLdMetadata}
        {breadcrumbsLdJson && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(breadcrumbsLdJson),
            }}
          />
        )}
        {article.socialCopy?.social
          ? <meta name="socialCopy" content={article.socialCopy.social} />
          : null}
      </Head>

      {constructMetadata(article, cardOverride || card, vertical, userAgent, fullUrl)}
    </>
  );
}

LiveBlogMetadata.propTypes = {
  article: PropTypes.shape(ArticlePropType).isRequired,
  cards: PropTypes.arrayOf(LiveBlogCardPropType).isRequired,
  card: PropTypes.shape(LiveBlogCardPropType).isRequired,
  vertical: PropTypes.string.isRequired,
  isRegularBlog: PropTypes.bool.isRequired,
  cardOverrideId: PropTypes.string.isRequired,
  userAgent: PropTypes.string.isRequired,
  fullUrl: PropTypes.string.isRequired,
};

const memoizedLiveBlogMetadata = memo(LiveBlogMetadata);

export { memoizedLiveBlogMetadata as LiveBlogMetadata };
