import Logo from 'components/TVE/Logo';
import React from 'react';
import SignIn from 'components/TVE/SignIn';
import classNames from 'classnames';
import { block } from '../helper';

export function MSNBCPlaymakerAuthedSubheader() {
  return (
    <div
      className={
        classNames(
          `${block}__authed__subheader`,
        )
      }
      data-testid={`${block}__authed__subheader`}
    >
      <div className={`${block}__authed__subheader__label`}>
        <lable>
          Signed In with
        </lable>

        <div className={`${block}__authed__subheader__logo`}>
          <Logo useColorLogo inheritHeight />
        </div>
      </div>
      <SignIn />
    </div>
  );
}
