import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import ArticleHero from 'components/Article/ArticleHero';
import ArticleBody from 'components/Article/ArticleBody';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { SHOW_USER_ACTIONS } from 'lib/brandFeatures';
import { article as ArticlePropType, heroLayoutType as heroLayoutTypePropType } from 'lib/CustomPropTypes';
import { getSecondsElapsed } from 'lib/DateTime';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { logError } from 'lib/datadog';

import styles from './styles.module.scss';

function LiveBlogStacked({
  article,
  article: {
    date,
  },
  block,
  path,
  hasStickyVideo,
  isRegularBlog,
  vertical,
  isChromeless,
  isLiveBlogLive,
  showCreatedDate,
  activeItems,
  stylizedTag,
  heroLayoutType,
  latestPublishedCardDate,
}) {
  const userActionConfig = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
  const showUserActions = userActionConfig?.hideFromChromeless
    ? !isChromeless
    : !!userActionConfig;

  // if there's a card that was last published, use that date as "published date"
  const liveBlogDatePubished = latestPublishedCardDate || date?.publishedAt;
  const liveBlogDateCreated = date?.createdAt;

  const isUpdated = liveBlogDateCreated !== liveBlogDatePubished;

  const isPublishedSameAsCreated = dayjs(liveBlogDatePubished)
    .diff(liveBlogDateCreated, 's') <= 1; // During ingest the dates b/t the two can be a few ms off
  const isLiveBlogPublishedLessThan60sAgo = getSecondsElapsed(liveBlogDatePubished) < 60;
  const isLiveBlogJustPosted = isLiveBlogPublishedLessThan60sAgo
    && isPublishedSameAsCreated;

  // Only show timestamp in the byline area of the article body if:
  // 1. the last card published was more than 60s ago and
  // 2. the live blog has been updated (it's not the initial publish)
  const showBylineTimestamp = !isLiveBlogJustPosted && isUpdated;

  return (
    <article
      key={`liveBlogWrapper-${article.id}`}
      className={classNames(
        block,
        styles.liveBlog,
        {
          [styles.hasStickyVideo]: hasStickyVideo,
          [`${block}--active`]: isLiveBlogLive,
        },
      )}
      data-testid="live-blog-stacked"
    >
      <ErrorBoundary errorLogger={logError}>
        <ArticleHero
          content={article}
          isLiveBlog
          path={path}
          stylizedTag={!isRegularBlog && stylizedTag}
          vertical={vertical}
          heroLayoutType={heroLayoutType}
        />
      </ErrorBoundary>
      <ErrorBoundary errorLogger={logError}>
        <ArticleBody
          vertical={vertical}
          article={article}
          isLiveBlog
          index={0}
          path={path}
          isChromeless={isChromeless}
          showCreatedDate={showCreatedDate}
          shouldRenderRightRailTaboola={
            ({ isFirstSection }) => isFirstSection && activeItems.length > 1
          }
          getRightRailAdConfig={({ isFirstSection }) => (
            isFirstSection && activeItems.length > 1
              ? { display: true, slot: 'boxflex' }
              : { display: false }
          )}
          showUserActions={showUserActions}
          showBylineTimestamp={showBylineTimestamp}
          bylineTimestampDatePublished={liveBlogDatePubished}
        />
      </ErrorBoundary>
    </article>
  );
}

LiveBlogStacked.propTypes = {
  activeItems: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  article: ArticlePropType.isRequired,
  block: PropTypes.string,
  hasStickyVideo: PropTypes.bool,
  isChromeless: PropTypes.bool,
  isLiveBlogLive: PropTypes.bool,
  isRegularBlog: PropTypes.bool,
  path: PropTypes.string,
  showCreatedDate: PropTypes.bool,
  stylizedTag: PropTypes.element,
  vertical: PropTypes.string.isRequired,
  heroLayoutType: heroLayoutTypePropType,
  latestPublishedCardDate: PropTypes.string,
};

LiveBlogStacked.defaultProps = {
  activeItems: [],
  block: '',
  hasStickyVideo: false,
  isChromeless: false,
  isLiveBlogLive: false,
  isRegularBlog: false,
  path: '',
  showCreatedDate: true,
  stylizedTag: null,
  heroLayoutType: {},
  latestPublishedCardDate: undefined,
};

export { LiveBlogStacked };
