import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BylineList } from 'components/Byline';
import { SocialShareMenu } from 'components/SocialShareMenu';
import ContentTimestamp from 'components/ContentTimestamp';

import { article as ArticlePropType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

export function Header({
  className,
  showCreatedDate,
  article,
  headerRef,
  showUserActions,
  publishedAt: propPublishedAt,
}) {
  const {
    headline,
    dek,
    authors,
    url,
    id,
    date: { createdAt, publishedAt: articlePublishedAt },
  } = article;
  return (
    <header className={classNames(className, styles.header)} ref={headerRef}>
      <div>
        <ContentTimestamp
          dateCreated={createdAt}
          datePublished={propPublishedAt || articlePublishedAt}
          showCreatedDate={showCreatedDate}
          delimiter=", "
          hideMicroData
          meta
          styles={styles.timestamp}
        />
        <h1 className={styles.headline}>{headline.primary}</h1>
        {dek && <p className={styles.dek} data-testid="dek">{dek}</p>}
        <div className={styles.subline}>
          {authors.length > 0
          && (
            <div className={styles.byline} data-testid="authors">
              <span>By&nbsp;</span>
              <BylineList authors={authors} />
            </div>
          )}
          {showUserActions && (
            <SocialShareMenu
              trackingEventName="article_social_share"
              contentId={id}
              url={url.primary}
              headline={headline.tease}
              tooltipAlign="right"
            />
          )}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  article: ArticlePropType.isRequired,
  className: PropTypes.string,
  showCreatedDate: PropTypes.bool,
  headerRef: PropTypes.oneOfType([
    // PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    PropTypes.func,
  ]),
  showUserActions: PropTypes.bool,
  publishedAt: PropTypes.string,
};

Header.defaultProps = {
  className: undefined,
  showCreatedDate: true,
  headerRef: undefined,
  showUserActions: true,
  publishedAt: undefined,
};
