const socialCrawlBotUserAgents = [
  // Facebook
  // https://developers.facebook.com/docs/sharing/webmasters/crawler/
  'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
  'facebookexternalhit/1.1',
  'facebookcatalog/1.0',

  // Twitter
  // https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
  'Twitterbot',

  // SocialFlow
  // https://support.socialflow.com/hc/en-us/articles/212553626-What-User-Agents-should-our-team-whitelist-
  'SocialFlow/0.10',
  'SocialFlow/2014',
  'SocialFlow/2015',
  'SocialFlow/2016',
  'Net::Curl::Simple v0.13',
];
/**
 * Detect a social crawl bot user agent. For use in serving live blog post social data
 * @param {string} userAgent - The user agent string
 * @returns {boolean}
 */
function isSocialCrawlBotUserAgent(userAgent) {
  // Account for version changes (e.g., SocialFlow/2016 => SocialFlow/2025)
  const versionedSocialCrawlBotUserAgents = socialCrawlBotUserAgents.filter((ua) => ua.match('/'));

  const versionedSocialCrawlBotUserAgentNames = [
    ...new Set(
      versionedSocialCrawlBotUserAgents.map((ua) => {
        const [userAgentName] = ua.split('/');
        return userAgentName;
      }),
    ),
  ];

  const socialCrawlBotUserAgentNamePattern = new RegExp(
    versionedSocialCrawlBotUserAgentNames.join('|'),
    'ig',
  );

  const isSocialCrawler = Boolean(
    socialCrawlBotUserAgents.includes(userAgent)
    || userAgent.match(socialCrawlBotUserAgentNamePattern),
  );

  return isSocialCrawler;
}

export { isSocialCrawlBotUserAgent };
