import React from 'react';
import get from 'lodash.get';
import { isBlogEventEnded, isBlogLive } from 'lib/liveBlog';
import {
  heroLayoutType as heroLayoutTypePropType,
  article as ArticlePropType,
} from 'lib/CustomPropTypes';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import LiveFlag from 'components/LiveFlag';
import i18next from 'i18next';
import { TimestampWithUpdatedText } from 'components/TimeStampWithUpdatedText';
import PropTypes from 'prop-types';

dayjs.extend(isSameOrAfter);

/**
 * @param {object} props
 * @param {string} [props.latestDate]
 * @param {string} props.vertical
 * @param {{ default: boolean, wide: boolean }} [props.heroLayoutType]
 * @param {import('../../lib/graphqlTypeDefs.js').Article[]} props.articles
 * @returns {React.ReactElement}
 */
export const StylizedTag = (props) => {
  const {
    latestDate,
    vertical,
    heroLayoutType,
    articles,
  } = props;
  let label = i18next.t('EVENT ENDED');
  const flag = get(props, 'articles[0].flag', null);
  const dateCreated = get(props, 'articles[0].date.createdAt', null);
  const datePublished = get(props, 'articles[0].date.publishedAt', null);
  // custom end time entered in the CMS
  const eventDateTimeEndsAt = articles?.[0]?.eventDateTime?.endsAt;
  const useEventEndedFromCmsData = typeof eventDateTimeEndsAt === 'string'
    && dayjs(eventDateTimeEndsAt).isValid();

  // Need date value from an item to check if !useEventEndedFromCmsData
  if (!useEventEndedFromCmsData && !latestDate) {
    return null;
  }

  const eventEnded = useEventEndedFromCmsData
    ? dayjs().isSameOrAfter(eventDateTimeEndsAt)
    : isBlogEventEnded(flag) || !isBlogLive(latestDate);
  const isInitiallyPublished = dayjs(datePublished).diff(dateCreated, 's') <= 1;

  if (!eventEnded) {
    label = i18next.t(typeof flag === 'string' && flag.length ? flag : 'LIVE COVERAGE');
  }

  return (
    <LiveFlag
      label={label}
      heroLayoutType={heroLayoutType}
      timestampWithUpdatedText={(
        <TimestampWithUpdatedText {...{
          dateCreated,
          datePublished,
          latestDate,
          eventEnded,
          vertical,
          isInitiallyPublished,
        }}
        />
      )}
    />
  );
};

StylizedTag.propTypes = {
  vertical: PropTypes.string.isRequired,
  latestDate: PropTypes.string,
  heroLayoutType: heroLayoutTypePropType,
  articles: PropTypes.arrayOf(ArticlePropType).isRequired,
};

StylizedTag.defaultProps = {
  latestDate: '',
  heroLayoutType: {},
};
